import { Button } from "@mui/material";
import { UserType } from "./types";
import CSS from "csstype";

const userStyle: CSS.Properties = {
  float: "right"
};

interface MenuPropsType {
  logout: Function;
  user?: UserType;
}

const Menu = (props: MenuPropsType) => {
  return <div>
    <a href="/users">
      <Button color="primary">Users</Button>
    </a>
    <a href="/conversations">
      <Button color="primary">Conversations</Button>
    </a>
    <div style={userStyle}>
      {props.user && props.user.name}
      <Button color="secondary" onClick={() => props.logout()}>Logout</Button>
    </div>
  </div>;
};

export default Menu;
