import { useState } from "react";
import { TextField } from "@mui/material";

interface ChatMessageEntryPropsType {
  onSubmit?: Function;
  sendReply?: Function;
  replyTo?: string;
}

const ChatMessageEntry = (props: ChatMessageEntryPropsType) => {
  const [newMessage, setNewMessage] = useState<string>("");

  return <TextField fullWidth value={newMessage} onChange={(e) => setNewMessage(e.target.value)} onKeyUp={(e) => {
    if(e.key !== "Enter") return;
    if(props.onSubmit) {
      props.onSubmit(newMessage);
      setNewMessage("");
      return;
    }
    if(props.sendReply) {
      props.sendReply(props.replyTo, newMessage);
      setNewMessage("");
    }
  }} />
};

export default ChatMessageEntry;
