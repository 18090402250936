import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { postAndThen } from "./utils/fetchAndThen";
import { LOGIN_ENDPOINT } from "./endpoints";

interface LoginType {
  updateAPItoken: Function;
  updatePusherKey: Function;
  updateUserId: Function;
}

const Login = (props: LoginType) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const login = () => {
    const userLogin = {
      email: email,
      password: password
    };
    postAndThen(LOGIN_ENDPOINT, userLogin, (data: any) => {
      props.updateUserId(data.success.user.id);
      props.updateAPItoken(data.success.user.api_token);
      props.updatePusherKey(data.success.pusher_key);
    });
  };

  return <div>
    <h1>Proxi</h1>
    <form>
      <TextField id="email" label="Email address" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
      <TextField id="password" label="Password" type="password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
      <Button color="secondary" onClick={() => login()}>Login</Button>
    </form>
  </div>;
};

export default Login;
