import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { deleteAndThen, getAndThen, postAndThen } from "./utils/fetchAndThen";
import { CONVERSATIONS_ENDPOINT, USERS_ENDPOINT } from "./endpoints";
import { ChatMessageType, ChatMessagesType, UserType, UsersType } from "./types";
import ChatMessage from "./ChatMessage";
import ChatMessageEntry from "./ChatMessageEntry";
import CSS from "csstype";

const addReply = (tree: ChatMessagesType, replyingTo: string, message: ChatMessageType) => {
  if(tree[replyingTo]) {
    if(!tree[replyingTo].replies) {
      tree[replyingTo].replies = {};
    }
    tree[replyingTo].replies![message.id] = message;
    return;
  }

  Object.keys(tree).forEach((k) => {
    if(!tree[k].replies) {
      tree[k].replies = {};
    }
    addReply(tree[k].replies, replyingTo, message);
  });
};

const generateChatTree = (source: ChatMessageType[]) => {
  const tree: ChatMessagesType = {};
  source.forEach((message) => {
    if(message.reply_to) {
      addReply(tree, message.reply_to, message);
    } else {
      tree[message.id] = message;
    }
  });
  return tree;
};

const ownerStyle: CSS.Properties = {
  color: "green"
};

interface ConversationType {
  id?: string;
  user_id?: string;
  name?: string;
  messages?: any;
  users?: UserType[];
}

interface ConversationPropsType extends ConversationType {
}

const Conversation = (props: ConversationPropsType) => {
  const { id } = useParams();
  const [users, setUsers] = useState<UsersType>();
  const [conversation, setConversation] = useState<ConversationType>();
  const [messages, setMessages] = useState<ChatMessagesType>();

  const getConversation = useCallback(() => {
    getAndThen(CONVERSATIONS_ENDPOINT + "/" + id, (data: ConversationType) => {
      const results = generateChatTree(data.messages);
      setMessages(results);
      setConversation(data)
    });
  }, [setConversation, setMessages, id]);

  useEffect(() => {
    getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
    getConversation();

    const interval = setInterval(() => {
      getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
      getConversation();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [getConversation, setConversation, setUsers]);

  const sendMessage = (newMessage: string) => {
    postAndThen(CONVERSATIONS_ENDPOINT + "/" + id + "/message", { message: newMessage }, () => {
      getConversation();
    });
  };

  const sendReply = (replyTo: string, message: string) => {
    postAndThen(CONVERSATIONS_ENDPOINT + "/" + id + "/message", { message: message, reply_to: replyTo }, () => {
      getConversation();
    });
  };

  const deleteMessage = (messageId: string) => {
    deleteAndThen(CONVERSATIONS_ENDPOINT + "/" + id + "/message/" + messageId, () => {
      getConversation();
    });
  };

  const removeUser = (userId: string) => {
    deleteAndThen(CONVERSATIONS_ENDPOINT + "/" + id + "/user/" + userId, () => {
      getConversation();
    });
  };

  return <div>
    <h2>Conversation {conversation && "- " + conversation.name}</h2>
    { conversation &&
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Participants</TableCell>
          <TableCell>
            {conversation.users && conversation.users.map((user) => {
              if(user.id === conversation.user_id) {
                return <div key={user.id} style={ownerStyle}>{user.name}</div>;
              }
              return <div key={user.id}>
                {user.name} <Button onClick={() => removeUser(user.id)}>Remove</Button>
              </div>;
            })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Message count</TableCell>
          <TableCell>{conversation.messages ? conversation.messages.length : 0}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>
            <ChatMessageEntry onSubmit={sendMessage} sendReply={sendReply} />
          </TableCell>
        </TableRow>
        { users &&
          messages &&
          Object.keys(messages).map((k: string) => {
            const message = messages[k];
            return <ChatMessage key={message.id} {...message} users={users} deleteMessage={deleteMessage} sendReply={sendReply} />;
          })
        }
      </TableBody>
    </Table>
    }
  </div>;
};

export default Conversation;
