import { useState } from "react";
import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import ChatMessageEntry from "./ChatMessageEntry";
import { ChatMessageType, UsersType } from "./types";
import CSS from "csstype";

const hideStyle: CSS.Properties = {
  display: "none"
};

const chatMessageStyle: CSS.Properties = {
  borderBottom: "thick solid black"
};

interface ChatMessagePropsType extends ChatMessageType {
  users: UsersType;
  deleteMessage: Function;
  sendReply: Function;
}

const ChatMessage = (props: ChatMessagePropsType) => {
  const [replyEntryVisible, setReplyEntryVisible] = useState<boolean>(false);
  const date = new Date(props.created_at);
  const repliesStyle = props.replies ? {} : hideStyle;
  return <>
    <TableRow>
      <TableCell colSpan={3}>{props.deleted_at ? "DELETED" : props.message}</TableCell>
    </TableRow>
    <TableRow style={chatMessageStyle}>
      <TableCell><small>{date.toLocaleString()}</small></TableCell>
      <TableCell><small>{props.users[props.author_id].name}</small></TableCell>
      <TableCell>
        <Button onClick={() => setReplyEntryVisible(true)}>Reply</Button>
        <Button onClick={() => props.deleteMessage(props.id)}>Delete</Button>
      </TableCell>
    </TableRow>
    <TableRow style={repliesStyle}>
      <TableCell colSpan={3}>
        <Table>
          <TableBody>
        {props.replies && Object.keys(props.replies).map((k) => {
          const message = props.replies![k];
          return <ChatMessage key={message.id} {...message} users={props.users} deleteMessage={props.deleteMessage} sendReply={props.sendReply} />;
        })}
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
    <TableRow style={replyEntryVisible ? {} : hideStyle}>
      <TableCell></TableCell>
      <TableCell colSpan={2}>
        <ChatMessageEntry replyTo={props.id} sendReply={props.sendReply} />
      </TableCell>
    </TableRow>
  </>;
};

export default ChatMessage;
