import { useEffect, useState } from "react";
import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { deleteAndThen, getAndThen } from "./utils/fetchAndThen";
import { USERS_ENDPOINT } from "./endpoints";
import Location, { LocationType } from "./Location";

interface UserType {
  id: string;
  name: string;
  email: string;
  available: boolean;
  location: LocationType;
}

interface UsersType {
  [index: string]: UserType;
}

interface UserPropsType extends UserType {
  deleteUser: Function;
}

const User = (props: UserPropsType) => {
  return <TableRow>
    <TableCell>
      <a href={"/user/" + props.id}>
        {props.name}
      </a>
    </TableCell>
    <TableCell>
      <a href={"/user/" + props.id}>
        {props.email}
      </a>
    </TableCell>
    <TableCell>
      Available: {props.available ? "Yes" : "No"}
    </TableCell>
    <TableCell>
      Most recent location:<br />
      <Location {...props.location} />
    </TableCell>
    <TableCell>
      <Button onClick={() => props.deleteUser(props.id) }>Delete</Button>
    </TableCell>
  </TableRow>;
};

interface UsersPropsType {
}

const Users = (props: UsersPropsType) => {
  const [users, setUsers] = useState<UsersType>();

  const deleteUser = (id: string) => {
    deleteAndThen(USERS_ENDPOINT + "/" + id, () => {
      getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
    });
  };

  useEffect(() => {
    getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
  }, [setUsers]);

  return <div>
    <h2>Users</h2>
    <Table>
      <TableBody>
        {users && Object.keys(users).map((id) => <User {...users[id]} deleteUser={deleteUser} key={id} />)}
      </TableBody>
    </Table>
  </div>;
};

export default Users;
