import { useEffect, useState } from "react";
import { getAndThen } from "./utils/fetchAndThen";
import { CONVERSATIONS_ENDPOINT } from "./endpoints";
import { ConversationType } from "./types";

interface ConversationSelectPropsType {
  onChange: Function;
  justMine: boolean;
}

const ConversationSelect = (props: ConversationSelectPropsType) => {
  const [conversations, setConversations] = useState<ConversationType[]>();
  const [selection, setSelection] = useState<string>("0");

  useEffect(() => {
    getAndThen(CONVERSATIONS_ENDPOINT + (props.justMine ? "?owner=true" : ""), (data: ConversationType[]) => setConversations(data));
  }, [setConversations, props.justMine]);

  const onChange = (id: string) => {
    props.onChange(id);
    setSelection("0");
  };

  return <select onChange={(e) => onChange(e.target.value)} value={selection}>
    <option value={"0"}>Add user to conversation...</option>
    {conversations &&
      conversations.map((convo) => <option key={convo.id} value={convo.id}>{convo.name}</option>)
    }
  </select>;
};

export default ConversationSelect;
