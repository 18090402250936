import { useEffect, useState } from "react";
import { Button, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { deleteAndThen, getAndThen, postAndThen } from "./utils/fetchAndThen";
import { CONVERSATIONS_ENDPOINT, USERS_ENDPOINT } from "./endpoints";
import { ConversationType, UserType, UsersType } from "./types";

interface ConversationPropsType extends ConversationType {
  user?: UserType;
  deleteConversation: Function;
}

const Conversation = (props: ConversationPropsType) => {
  return <TableRow>
    <TableCell onClick={() => window.location.href = "/conversation/" + props.id}>{props.name}</TableCell>
    <TableCell>{props.user ? props.user.name : props.user_id}</TableCell>
    <TableCell>{props.messages.length}</TableCell>
    <TableCell>
      <Button onClick={() => props.deleteConversation(props.id)}>Delete</Button>
    </TableCell>
  </TableRow>;
};

interface ConversationsPropsType {
}

const Conversations = (props: ConversationsPropsType) => {
  const [justMine, setJustMine] = useState<boolean>(true);
  const [users, setUsers] = useState<UsersType>();
  const [conversations, setConversations] = useState<ConversationType[]>();
  const [newConversationName, setNewConversationName] = useState<string>("");

  const deleteConversation = (id: string) => {
    deleteAndThen(CONVERSATIONS_ENDPOINT + "/" + id, () => {
      const url = CONVERSATIONS_ENDPOINT + (justMine ? "?owner=true" : "");
      getAndThen(url, (data: ConversationType[]) => setConversations(data));
    });
  };

  const createConversation = () => {
    postAndThen(CONVERSATIONS_ENDPOINT, { name: newConversationName }, () => {
      setNewConversationName("");
      const url = CONVERSATIONS_ENDPOINT + (justMine ? "?owner=true" : "");
      getAndThen(url, (data: ConversationType[]) => setConversations(data));
    });
  };

  useEffect(() => {
    const url = CONVERSATIONS_ENDPOINT + (justMine ? "?owner=true" : "");
    getAndThen(url, (data: ConversationType[]) => setConversations(data));
    getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
  }, [setConversations, justMine, setUsers]);

  return <div>
    <h2>Conversations</h2>
    <FormControlLabel
      control={<Checkbox checked={justMine} onChange={(e) => setJustMine(e.target.checked)} />}
      label="Only my conversations"
    />
    <div>
      <TextField value={newConversationName} onChange={(e) => setNewConversationName(e.target.value)} />
      <Button onClick={createConversation}>Create</Button>
    </div>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Owner</TableCell>
          <TableCell>Message count</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {conversations && conversations.map((convo) => {
          return <Conversation key={convo.id} {...convo} deleteConversation={deleteConversation} user={users && users[convo.user_id]} />
        })} 
      </TableBody>
    </Table>
  </div>;
};

export default Conversations;
