import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Switch, Table, TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { CONVERSATIONS_ENDPOINT, USERS_ENDPOINT } from "./endpoints";
import { getAndThen, postAndThen } from "./utils/fetchAndThen";
import Location, { LocationType } from "./Location";
import ConversationSelect from "./ConversationSelect";
import { ConversationType } from "./types";

interface ProfileType {
  id: string;
  description: string;
  phone: string;
}

interface UserType {
  id: string;
  name: string;
  email: string;
  available: boolean;
  location: LocationType;
  password: string;
  c_password: string;
  profile: ProfileType;
  updated: string;
  created: string;
  conversations?: ConversationType[];
}

interface UserPropsType {
}

const User = (props: UserPropsType) => {
  const { id } = useParams();
  const [user, setUser] = useState<UserType>();

  const getUser = useCallback(() => {
    getAndThen(USERS_ENDPOINT + "/" + id, (data: UserType) => {
      if(!data.profile) {
        data.profile = {
          id: "",
          description: "",
          phone: ""
        };
      }
      setUser({...data, password: "", c_password: ""});
    });
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const save = () => {
    postAndThen(USERS_ENDPOINT + "/" + id, user, () => {});
  };

  const addUserToConversation = (conversationId: string) => {
    postAndThen(CONVERSATIONS_ENDPOINT + "/" + conversationId + "/user", { user_id: id }, getUser);
  };

  if(user) return <div>
    <h2>{user.name}</h2>
    <form autoComplete="off">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField id="name" name="name" label="Name" value={user.name} fullWidth onChange={(e) => setUser({...user, name: e.target.value})} />
            </TableCell>
            <TableCell>
              <TextField id="email" name="email" label="Email" value={user.email} fullWidth onChange={(e) => setUser({...user, email: e.target.value})} />
            </TableCell>
            <TableCell>
              <label htmlFor="available">Available</label>
              <Switch name="available" id="available" checked={user.available} onChange={(e) => setUser({...user, available: e.target.checked})} /> 
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField type="password" id="password" name="password" label="Password"
                         fullWidth value={user.password} onChange={(e) => setUser({...user, password: e.target.value})} />
            </TableCell>
            <TableCell>
              <TextField type="password" id="c_password" name="c_password" label="Confirm Password"
                         value={user.c_password} fullWidth onChange={(e) => setUser({...user, c_password: e.target.value})} />
            </TableCell>
            <TableCell>
              <Button onClick={() => save()}>Save</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <h4>Profile</h4>
              <TextField id="description" name="description" label="Description" fullWidth
                         value={user.profile.description} onChange={() => {}} />
            </TableCell>
            <TableCell>
              <Button onClick={() => {}}>Send password reset</Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField id="phone" name="phone" label="Phone"
                         value={user.profile.phone} onChange={() => {}} />
            </TableCell>
            <TableCell>
              Created: {user.created}<br />
              Last update: {user.updated}
            </TableCell>
            <TableCell>
              <Location {...user.location} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </form>
    <div>
      Conversations:
      {user.conversations && user.conversations.map((convo) => <div key={convo.id}>{convo.name}</div>)}
      <ConversationSelect justMine={true} onChange={addUserToConversation} />
    </div>
  </div>;
  else return <></>;
};

export default User;
