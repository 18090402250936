import {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Route,
  Routes,
} from "react-router-dom";
import {
  setAxiosAuthToken,
  setInterceptors,
} from "./utils/axiosConfig";
import Pusher from "pusher-js";
import Login from "./Login";
import Menu from "./Menu";
import Users from "./Users";
import User from "./User";
import Conversations from "./Conversations";
import Conversation from "./Conversation";
import CSS from "csstype";
import { UsersType } from "./types";
import { getAndThen } from "./utils/fetchAndThen";
import { USERS_ENDPOINT } from "./endpoints";

const appStyle: CSS.Properties = {
  margin: "1%"
};

const App = () => {
  const [users, setUsers] = useState<UsersType>();
  const [userId, setUserId] = useState<string>(localStorage.getItem("userId") || "");
  const [token, setToken] = useState<string>(localStorage.getItem("token") || "");
  const [pusherKey, setPusherKey] = useState<string>(localStorage.getItem("pusherKey") || "");
  const pusherRef = useRef<Pusher>();

  useEffect(() => {
    if(pusherKey.length) {
/*
      pusherRef.current = new Pusher(pusherKey, {
        wsHost: "proxi.imperian.systems",
        wsPort: 6001,
        wssPort: 6001,
        wsPath: "",
        disableStats: true,
        authEndpoint : "https://proxi.imperian.systems/api/wsLogin",
        auth: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        },
        enabledTransports: ['wss', 'ws'],
      });

      pusherRef.current.subscribe("private-test-channel")
      .bind("message", (data: any) => {
        console.log(data);
      });
*/
    }
  }, [pusherKey, token]);

  token ? setAxiosAuthToken(token) : setAxiosAuthToken();
  setInterceptors();

  const logout = () => {
    setToken("");
    setUserId("");
    setPusherKey("");
  };

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    localStorage.setItem("userId", userId);
  }, [userId]);

  useEffect(() => {
    if(token) getAndThen(USERS_ENDPOINT, (data: UsersType) => setUsers(data));
    localStorage.setItem("pusherKey", pusherKey);
  }, [pusherKey, setUsers, token]);

  return <div style={appStyle}>
    {token === "" ? (
      <Login updateAPItoken={setToken} updatePusherKey={setPusherKey} updateUserId={setUserId} />
    ) : (
      <>
        <Menu logout={logout} user={users && users[userId]} />
        <Routes>
          <Route path="/users" element={<Users />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route path="/conversation/:id" element={<Conversation />} />
        </Routes>
      </>
    )}
  </div>;
};

export default App;
