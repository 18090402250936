export interface LocationType {
  latitude: string;
  longitude: string;
  timestamp: string;
}

interface LocationPropsType extends LocationType {
}

const Location = (props: LocationPropsType) => {
  return <>
    Lat {props.latitude}<br />
    Long {props.longitude}<br />
    {props.timestamp}
  </>;
}

export default Location;
